import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const High = styled.span`
  color: red;
`

const Reason = styled.span`
  font-size: 12px;
`

export const HighLow = ({
  value,
  has75PercentileCategoryReason,
  has25PercentileCategoryReason,
}) => {
  if (typeof value === 'number') {
    if (value === 1) {
      return (
        <Fragment>
          <High>
            High{' '}
            {has75PercentileCategoryReason && (
              <Reason>(Above the 75th percentile.)</Reason>
            )}
            {has25PercentileCategoryReason && (
              <Reason>(Below the 25th percentile.)</Reason>
            )}
          </High>
        </Fragment>
      )
    } else if (value === 0) {
      return (
        <Fragment>
          Low{' '}
          {has75PercentileCategoryReason && (
            <Reason>(Below the 75th percentile.)</Reason>
          )}
          {has25PercentileCategoryReason && (
            <Reason>(Above the 25th percentile.)</Reason>
          )}
        </Fragment>
      )
    }
    return value.toFixed(2)
  }
  return value
}

HighLow.defaultProps = {
  has75PercentileCategoryReason: false,
  has25PercentileCategoryReason: false,
}

HighLow.propTypes = {
  value: PropTypes.element,
  has75PercentileCategoryReason: PropTypes.bool,
  has25PercentileCategoryReason: PropTypes.bool,
}
